import { useStaticQuery, graphql, Link } from 'gatsby'
import { MicroCmsBlog } from '../../types'
interface PagenationsProps {
  currentId: string
}

export const Pagenations = ({ currentId }: PagenationsProps) => {
  const data = useStaticQuery(graphql`
    query {
      allMicrocmsBlog {
        nodes {
          id
          title
        }
      }
    }
  `)
  const contents: MicroCmsBlog[] = data.allMicrocmsBlog.nodes
  const index = contents.findIndex((content) => content.id === currentId)
  const prev = contents[index - 1]
  const next = contents[index + 1]
  return (
    <nav className="blog-post-nav">
      <ul
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          listStyle: 'none',
          padding: 0
        }}
      >
        <li>
          {prev && (
            <Link to={`/blog/${prev.id}`} rel="prev">
              ← {prev.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={`/blog/${next.id}`} rel="next">
              {next.title} →
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}
