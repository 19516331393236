import { graphql } from 'gatsby'

import { MicroCmsBlog, SiteData } from '../../../types'
import { Bio } from '../../components/Bio'
import { Layout } from '../../components/Layout'
import { Pagenations } from '../../components/Pagenations'
import { Seo } from '../../components/Seo'

interface BlogPostTemplateProps {
  data: {
    site: SiteData
    microcmsBlog: MicroCmsBlog
  }
  location: Location
}

const BlogPostTemplate = ({ data, location }: BlogPostTemplateProps) => {
  const post: MicroCmsBlog = data.microcmsBlog
  const siteTitle = data.site.siteMetadata?.title || 'Title'
  const date = post.createdAt.split('T')[0]

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.description}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.title}</h1>
          {/* <p>{date}</p> */}
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.content }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <Pagenations currentId={post.id} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPosts(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    microcmsBlog(id: { eq: $id }) {
      id
      content
      createdAt
      title
      description
      sortIndex
    }
  }
`
